import { useStoreState } from 'pullstate';
import DslStore, { fetchUsers, setCommunity, setCommunityCopy, setLastUsers } from '../../store/DslStore';
import { getCurrentUser, getLastUsers, getTopOfTheWeek } from '../../store/Selectors';
import { CustomCommunityProps, User } from '../../global';
import DesignerSquare from './DesignerSquare/DesignerSquare';
import { chunkArray, fullPathLogoCommunity, parseNumberFromEnd } from '../../helpers/utils';
import axiosHelper from '../../helpers/axiosHelper';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

interface Placeholder {}
interface Props {
  title: string;
  count: string;
  which: string;
}

const Members: React.FC<Props> = ({ title, count, which }) => {
  const generatePlaceholder = (): Placeholder => {
    return {};
  };
  const divRef = useRef<HTMLDivElement>(null);
  const [wh, setWH] = useState(280);
  const [chunkedData, setChunkedData] = useState<User[][]>();
  const [chunkedPlaceholder] = useState<Placeholder[][]>(
    chunkArray(
      Array.from({ length: parseNumberFromEnd(count) }, () => generatePlaceholder()),
      4
    )
  );
  useEffect(() => {
    const updateWidth = () => {
      if (divRef.current) {
        if (divRef.current.clientWidth>=1180){
          setWH(280);
        } else if (divRef.current.clientWidth>=980){
          setWH(230);
        } else if (divRef.current.clientWidth>=720){
          setWH(370);
        } else {
          setWH(divRef.current.clientWidth);
        }
        console.log(divRef.current.clientWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {

    console.log('Members useEffect' );
    console.log(which);
    console.log(count);
    console.log("fine use effect");

    const fetchUsers = async () => {
      var users: User[] = [];
      switch (which) {
        case 'Last':
          users = await axiosHelper.retrieveLastUsers(parseNumberFromEnd(count));
          setChunkedData(chunkArray(users, parseNumberFromEnd(count)));
          break;
        case 'Top of the week':
          users = await axiosHelper.retrieveTopOfTheWeek(parseNumberFromEnd(count));
          setChunkedData(chunkArray(users, parseNumberFromEnd(count)));
          break;
        case 'Top of all times':
          users = await axiosHelper.retrieveTopOfAllTimes(parseNumberFromEnd(count));
          setChunkedData(chunkArray(users, parseNumberFromEnd(count)));
          break;
        default:
          break;
      }
    };

    fetchUsers();
  }, [count, which]);

  return (
    <section className="container py-5">
      <div className="row">
        <div className="col-12">
          <h2 className="fw-bold color-dark px-3 mb-3">{title} &nbsp;</h2>
        </div>
      </div>
      <div ref={divRef} className="row mb-5 mt-3">
        {chunkedData == null ? (
          <>
            {chunkedPlaceholder.map((chunk, index) => (
              <div className="row" key={index}>
                {chunk.map((user, itemIndex) => (
                  <div
                    className="col-12 col-md-6  mb-4 col-lg-3 d-flex flex-row align-items-center justify-content-center"
                    key={itemIndex}
                  >
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px'}} width={wh} height={wh}  />
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <>
            {chunkedData.map((chunk, index) => (
              <div className="row" key={index}>
                {chunk.map((user, itemIndex) => (
                  <div
                    className="col-12 col-md-6  mb-4 col-lg-3 d-flex flex-row align-items-center justify-content-center"
                    key={itemIndex}
                  >
                    <DesignerSquare
                      user={user}
                    />
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default Members;
