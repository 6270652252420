import React, { useEffect, useState } from 'react';
import CardInfo, { ICardInfo } from '../CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faUser, faTrophy, faHeart, faMapMarkerAlt, faDollarSign, faPencil } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../ActionButton/ActionButton';

import styles from './DesignerCard.module.scss';
import { User, UsersILike } from '../../../global';
import { useNavigate } from 'react-router-dom';
import axiosHelper from '../../../helpers/axiosHelper';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { useAuth } from '../../../context/AuthContext';
import { jobString, locationStringForUser, numberVotes, registrationDateString } from '../../../helpers/utils';

interface IDesignerCard extends ICardInfo {
  user: User | UsersILike;
}

const DesignerCard: React.FC<IDesignerCard> = ({ user }) => {
  const redirect = useNavigate();
  const { loggedUser, isUserLiked, toggleUserLiked } = useAuth();

  const goToDetail = async () => {
    redirect(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/${ROUTEPATHS.COMMUNITY_PROFILE}`);
  };

  const goToUserGallery = async () => {
    console.log('gallery');
    redirect(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/${ROUTEPATHS.COMMUNITY_PORTFOLIO}`);
  };

  const banUser = async () => {
    if (loggedUser?.userType === 'G') {
      await axiosHelper
        .banUser(user.id)
        .then((response) => {
          console.log(response);
          // redirect('/');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={`${styles.card} h-100  col-12 px-lg-2 px-sm-2 position-relative d-flex flex-row align-items-center justify-content-center `}>
      <CardInfo user={user}>
        <div className="text-center ">
          {user.id !== loggedUser?.id && (
            <>
              {isUserLiked(user) ? (
                <ActionButton label="Remove from favorites" icon="confirm" position="top-right" color="success" action={() => toggleUserLiked(user)} />
              ) : (
                <ActionButton
                  icon="addFavorite"
                  label={loggedUser ? 'Save as favorite' : 'Login to save as favorite'}
                  color={!loggedUser ? 'light-gray-3' : ''}
                  position="top-right"
                  action={() => toggleUserLiked(user)}
                />
              )}
            </>
          )}
          {loggedUser?.userType === 'G' && <ActionButton label="Ban this user" icon="removeFavorite" position="top-left" color="danger" action={() => banUser()} />}
          <div className="container bg-light justify-content-center">
            <div className={`${styles.rowTitle} row  mb-2`}>
              <div className="col  text-center align-self-center  " style={{ overflow: 'hidden', cursor: 'pointer' }} onClick={() => goToDetail()}>
                <span className="fw-bold text-16 color-blue d-none d-md-block ">{user.username}</span>
                <span className="fw-bold text-16 color-blue d-md-none d-sm-block ">{user.username}</span>
              </div>
            </div>

            <div className=" row align-self-center text-center mb-5  text-capitalize">
              <span>{jobString(user.jobName)}</span>
            </div>
            <div className="d-flex flex-column align-items-center ">
              <div className="info d-flex flex-column justify-content-center " style={{ width: '70%' }}>
                <div className="d-flex justify-content-start  align-text-top mb-3" style={{ height: '35px' }}>
                  <div className="d-flex  justify-content-center align-items-center ">
                    {user.visibleLocation && <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className="text color-blue me-3" />}
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center  w-100 overflow-hidden">
                    <div className="text text-start ps-1 color-blue">{user.visibleLocation}</div>
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-3 ">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start align-self-center ">
                    <span className="text">Since: {registrationDateString(user.registrationDate)}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className=" text-start color-blue  align-self-center">
                    <span className="text">{user.type === 'Designer' ? 'User' : 'Client'}</span>
                  </div>
                </div>
                {user.type === 'Designer' ? (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className=" text-start color-blue" style={{ cursor: 'pointer' }} onClick={() => goToUserGallery()}>
                      <span className="fw-bold text">{numberVotes(user.designsCount)}</span> entries
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className=" text-start color-dark">
                      <span className="fw-bold text">{user.contestsCount}</span> contest
                    </div>
                  </div>
                )}

                {user.reputationCount ? (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className=" text-start color-dark">
                      <span className="fw-bold text">{user.reputationCount}</span> reputation
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className=" text-start color-dark">
                      <span className="fw-bold text">0</span> reputation
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faDollarSign as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-dark">
                    <span className="fw-bold text">€ {user.prizeAmount}</span> {user.type === 'Designer' ? 'won' : 'awarded'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardInfo>
    </div>
  );
};

export default DesignerCard;
