import React, { useEffect, useState } from 'react';
import SquaredImage from '../../SquaredImage/SquaredImage';

import styles from './cardInfo.module.scss';
import { User, UsersILike } from '../../../global';
import { useNavigate } from 'react-router-dom';
import profileImg from '../../../assets/Profile.png';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { cleanupHttpPath } from '../../../helpers/utils';
export interface ICardInfo {
  image?: string;
  circle?: boolean;
  altText?: string;
  name?: string;
  userId?: number;
  children?: React.ReactNode;
}

interface Props {
  user?: User | UsersILike;
  path?: string;
  circle?: boolean;
  children?: React.ReactNode;
}

const CardInfo: React.FC<Props> = ({ user, path, children, circle = true }) => {
  const redirect = useNavigate();
  const [image, setImage] = useState<string>(user?.logo ?? '');

  useEffect(() => {
    if (user) {
      if ('profilePic' in user && user.profilePic) {
        setImage(cleanupHttpPath(user.profilePic));
      }
    }
  }, [user]);

  useEffect(() => {
    if (path) {
      setImage(cleanupHttpPath(path));
    }
  }, [path]);

  const goToDetail = async () => {
    if (user) redirect(`/${ROUTEPATHS.COMMUNITY}/${user.nameForURL}/${ROUTEPATHS.COMMUNITY_PROFILE}`);
  };

  return (
    <div className={`${styles.cardInfoWrapper}  d-flex flex-column bg-light position-relative`}>
      <div className={`${image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light px-5 pt-4`} onClick={() => goToDetail()} style={{ cursor: 'pointer' }}>
        {image ? (
          // <img src={props.image} alt={props.altText} className={`${props.circle ?? 'circle'}`} />
          <SquaredImage image={cleanupHttpPath(image)} altText={user?.username} circle={circle} />
        ) : (
          <div className={`${styles.squaredImageWrapper}`}>
            <div className={`${styles.imageInner}`}>
              <img src={profileImg} alt="profile" />
              {/* <FontAwesomeIcon icon={faUser as IconProp} className="color-light " /> */}
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.cardInfoContent} bg-light`}>{children}</div>
    </div>
  );
};

export default CardInfo;
