import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faIndustry, faUser, faCalendar, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import AppFooter from '../../components/AppFooter/AppFooter';
import AppHeaderWLF from '../../components/AppHeader/AppHeaderWLF';
import { ProfileStatsWLF } from '../../components/Community/ContestStats/ProfileStatsWLF';
import { ProfileNavigation } from '../../components/PageNavigation/PROFILE/ProfileNavigation';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';
import { ProfileHeaderWLF } from '../../components/Profile/ProfileHeader/ProfileHeaderWLF';

import { useAuth } from '../../context/AuthContext';
import axiosHelper from '../../helpers/axiosHelper';
import { fullPathLogo, finalSlicedString, fullPathLogoUser, registrationDateString, locationString, locationStringForUser } from '../../helpers/utils';
import DslStore, { setWelcomeMessage } from '../../store/DslStore';
import { getFooterMenu, getHeaderMenu, getWelcomeMessage } from '../../store/Selectors';
import { ROUTEPATHS } from '../routePaths';
import ProfileEditable from './ProfileEditable';
import ProfileWLF from './SUBPAGES/ProfileWLF';
import AmbassadorWLF from './SUBPAGES/AmbassadorWLF';
import BadgesWLF from './SUBPAGES/BadgesWLF';
import FavoritesWLF from './SUBPAGES/FavoritesWLF';
import PortfolioWLF from './SUBPAGES/PortfolioWLF';
import AwardsWLF from './SUBPAGES/AwardsWLF';
import InspirationsWLF from './SUBPAGES/InspirationsWLF';
import { useParams } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import footerImg from '../../assets/footer_placeholder_transparent.png';

import { clientNavPages, designerNavPages, personalClientNavPages, personalProfileNavPages } from '../../mockdata/navPages';
import { INavLink, User } from '../../global';
import styles from './Profile.module.scss';
import ProfilePicWLF from '../../components/Profile/ProfilePic/ProfilePicWLF';
import ProfileNavigationWLF from '../../components/PageNavigation/PROFILE/ProfileNavigationWLF';
import { setCurrentPage } from '../../store/DslStore';
interface ProfileMainProps {
  isPreview?: boolean;
}

const ProfileMain: React.FC<ProfileMainProps> = ({ isPreview = false }) => {
  let { username } = useParams();
  const { pageName } = useParams();
  const [navPages, setNavPages] = useState<INavLink[]>(designerNavPages);
  const [currentPage, setCurrentPage] = useState<INavLink>(designerNavPages[0]);
  // const [editable, setEditable] = useState(false);
  const welcomeMessage = useStoreState(DslStore, getWelcomeMessage);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const footerMenu = useStoreState(DslStore, getFooterMenu);

  const { data, error, isLoading } = useSWR('user|' + username, axiosHelper.usersFetcher);
  const { loggedUser } = useAuth();
  const [messageBanner, setMessageBanner] = useState(0);
  const [successBanner, setSuccessBanner] = useState(0);
  const [popupMessage, setPopupMessage] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [editable, setEditable] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(`${ROUTEPATHS.COMMUNITY_PROFILE}`);

  useEffect(() => {
    console.log('DATA USEEFFECT');
    if (data) {
      console.log(data);
      setUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (loggedUser && user) {
      if (loggedUser.id === user.id) {
        if (!isPreview) {
          setEditable(true);
        }
      }
    }
  }, [loggedUser, user, isPreview]);

  useEffect(() => {
    if (user) {
      if (user.userType === 'D') {
        setNavPages(designerNavPages);
      } else if (user.userType === 'C') {
        setNavPages(clientNavPages);
      } else if (user.userType === 'G') {
        setNavPages(designerNavPages);
      }
    }
  }, [user]);


  const pageForTitle = useCallback(
    (name: string) => {
      const page = navPages.find((item) => item.text === `${name}`);
      if (page) {
        return page;
      } else {
        return navPages[0];
      }
    },
    [navPages]
  );

  useEffect(() => {
    const pageForName = (name: string) => {
        return navPages.find((item) => item.path === `/${name}`);
      };
  
    if (!pageName) {
      setCurrentPage(navPages[0]);
      return;
    }
    const page = pageForName(pageName);
    if (page) {
      setCurrentPage(page);
    } else {
      setCurrentPage(navPages[0]);
    }
  }, [pageName, navPages]);

  const userChanged = (updatedUser: User) => {
    console.log('USER CHANGED');
    mutate('user|' + username, updatedUser, { revalidate: false }); // Update localStorage cache
    setUser(updatedUser);
  };

  // if (!user) {
  //   return <></>;
  // }
  return (
    <>
      <div className="bg-light-gray">
        <AppHeaderWLF
          links={headerMenu}
          bgColor="#eab95a"
          logoImg={headerLogo}
          bgImage={headerimg}
          alpha={0.4}
          breadcrumbs={`Home/Community/${username}`}
          pageTitle={currentPage.text}
          hideSearch={true}
        />
        <ProfileNavigationWLF links={navPages} currentPage={currentPage} username={username!} />
        {user && (
          <>
            <div className={`  d-flex flex-row align-items-center w-100 justify-content-center ${welcomeMessage ? `${styles.successBackground}` : 'd-none'}`}>
              <div className="container d-flex flex-row align-items-center justify-content-center ">
                <div className="row col-12 ">
                  <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
                    <div className="col-12 text-center align-self-end ">
                      <span className="sub-title color-light">Welcome to Desall!</span>
                    </div>
                    <div>
                      <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setWelcomeMessage(false)}>
                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`  d-flex flex-row align-items-center w-100 justify-content-center 
                ${messageBanner === 1 ? `${styles.successBackground}` : messageBanner === 2 ? `${styles.errorBackground}` : 'd-none'}
        ${welcomeMessage ? `${styles.translateY60}` : ''}`}
            >
              <div className="container d-flex flex-row align-items-center justify-content-center ">
                <div className="row col-12 ">
                  <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
                    <div className="col-12 text-center align-self-end ">
                      <span className="sub-title color-light">{messageBanner === 1 ? 'Message sent!' : 'The mailserver is busy, please try again!'}</span>
                    </div>
                    <div>
                      <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setMessageBanner(0)}>
                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
                successBanner === 1
                  ? `${styles.successBackground}`
                  : successBanner === 2
                  ? `${styles.errorBackground}`
                  : successBanner === 3
                  ? `${styles.successBackground}`
                  : 'd-none'
              }
        ${welcomeMessage ? (messageBanner !== 0 ? `${styles.translateY120}` : `${styles.translateY60}`) : messageBanner !== 0 ? `${styles.translateY60}` : ''}`}
            >
              <div className="container  d-flex flex-row align-items-center justify-content-center">
                <div className="row col-12 ">
                  <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
                    <div className="col-12 text-center align-self-end ">
                      <span className="sub-title color-light">
                        {successBanner === 1 ? 'Updated successfully!' : successBanner === 3 ? 'The password has been changed!' : 'Update Problem! '}
                      </span>
                    </div>
                    <div>
                      <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setSuccessBanner(0)}>
                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <main className={`${styles.profileWrapper} container  px-md-3 pt-5  px-sm-0 `}>
          {user && (
            <>
              <div className="row ">
                <div className="col-12 px-md-2 mx-md-1 pe-md-3   ">
                  <ProfileHeaderWLF editable={editable && currentPage.id === 0} user={user} />
                </div>
              </div>
              <div className={`row px-4   mt-3 px-sm-0  `}>
                <div className="col-3 "></div>
                <div className="col-md-9 d-sm-none d-md-block">
                  <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(user?.username)}</h1>
                  <div className="sub-title-editable ms-4 color-dark text-capitalize">{user?.userType === 'D' ? user.job?.name : user?.sector}</div>
                </div>
                <div
                  className={`col-12 col-md-3 d-sm-flex align-items-sm-center justify-content-sm-center text-center position-relative ps-md-3 ${styles.profilePic} ${
                    user?.bannerAttachment ? styles.picUp : styles.minipicUp
                  } `}
                >
                  <ProfilePicWLF editable={editable && currentPage.id === 0} user={user} />
                </div>
                <div className="col-12 d-md-none  d-flex mt-10 justify-content-center">
                  <div className="text-center">
                    <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(user?.username)}</h1>
                    <div className="sub-title-editable ms-4 color-dark text-capitalize">{user?.userType === 'D' ? user.job?.name : user?.sector}</div>
                  </div>
                </div>

                <div className="col-12 col-md-3 mb-lg-3 ">
                  <div className="info ms-4   d-flex flex-column-reverse align-items-center h-100  ">
                    <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-5 mb-md-4 mb-3 ">
                      <div className="me-2  d-flex flex-row align-items-center">
                        {user?.userType === 'C' ? (
                          <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue " />
                        ) : (
                          <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-0" />
                        )}
                      </div>
                      <div className="text  col-11">{user?.userType === 'C' ? 'Company' : 'User'}</div>
                    </div>
                    <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4 mb-3  ">
                      <div className="me-2  d-flex flex-row align-items-center">
                        <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue " />
                      </div>
                      <div className="text col-11 ">since {registrationDateString(user?.registrationDate)}</div>
                    </div>
                    <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4  mb-3">
                      {(user?.visibleLocation) && (
                        <>
                          <div className="me-2  d-flex flex-row align-items-center">
                            <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue " />
                          </div>

                          <div className="text col-11">{user.visibleLocation}</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  pt-4  ">
                  <ProfileStatsWLF user={user} />
                </div>
              </div>
              {popupMessage && (
                <>
                  <PopUpSendMessage
                    popup={popupMessage}
                    close={() => setPopupMessage(false)}
                    setMessageNumber={(message) => (setMessageBanner(message), setPopupMessage(false))}
                    recipient={user.username}
                  />
                </>
              )}

              {currentPage.id === 0 && (
                <>
                  {editable ? (
                    <ProfileEditable user={user} callback={userChanged} successBanner={(n) => setSuccessBanner(n)} />
                  ) : (
                    <ProfileWLF user={user} loggedUser={loggedUser} setBreadcrumbs={setBreadcrumbs} setPopupMessage={setPopupMessage} />
                  )}
                </>
              )}
              {currentPage.id === 1 && <PortfolioWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
              {currentPage.id === 10 && <AmbassadorWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
              {currentPage.id === 2 && <InspirationsWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
              {currentPage.id === 3 && <FavoritesWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} />}
              {currentPage.id === 4 && <BadgesWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} setPopupMessage={setPopupMessage} />}
              {currentPage.id === 5 && <AwardsWLF user={user} loggedUser={loggedUser} breadcrumbs={setBreadcrumbs} setPopupMessage={setPopupMessage} />}
            </>
          )}
        </main>
        <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
      </div>
    </>
  );
};

export default ProfileMain;
